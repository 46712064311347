import React, { useState, useEffect } from 'react'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import AttachmentIcon from '@mui/icons-material/Attachment'
import SendIcon from '@mui/icons-material/Send'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'

import { useQuery } from '@apollo/client'
import moment from 'moment'
import DOMPurify from 'dompurify'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { loader } from 'graphql.macro'
import { v4 as uuidv4 } from 'uuid'

import Quill from 'quill'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { AttachmentList } from './AttachmentList' // Adjust path based on your project structure

import 'moment/locale/fr' // Import French locale

const ALL_TEMPLATES = loader('./graphql/allTemplates.graphql')

const EmailDrawer = ({
	open,
	onClose,
	emails,
	taskId,
	taskNumber,
	currentUser,
}) => {
	const [emailBody, setEmailBody] = useState('')
	const [attachments, setAttachments] = useState([])
	const [selectedTemplate, setSelectedTemplate] = useState('')
	const [templates, setTemplates] = useState([])
	const [isDialogOpen, setIsDialogOpen] = useState(false) // State for Dialog
	const [popupBody, setPopupBody] = useState('') // Temporary state for the popup tex

	// Apollo Query to fetch templates
	const { loading, error, data } = useQuery(ALL_TEMPLATES)

	// Set Moment.js to French
	moment.locale('fr')

	var sentBy = currentUser?.userName

	// Register inline styles
	const AlignStyle = Quill.import('attributors/style/align')
	Quill.register(AlignStyle, true)

	const DirectionStyle = Quill.import('attributors/style/direction')
	Quill.register(DirectionStyle, true)

	useEffect(() => {
		if (data && data.allTemplates) {
			setTemplates(data.allTemplates)
		}
	}, [data])

	const handleAttachmentClick = () => {
		const input = document.createElement('input')
		input.type = 'file'
		input.multiple = true
		input.onchange = (event) => {
			const files = Array.from(event.target.files)
			const newAttachments = files.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onload = () => {
						resolve({
							name: file.name,
							contentBytes: btoa(reader.result),
							contentType: file.type,
						})
					}
					reader.onerror = reject
					reader.readAsBinaryString(file)
				})
			})

			Promise.all(newAttachments).then((resolvedAttachments) => {
				setAttachments((prev) => [...prev, ...resolvedAttachments])
			})
		}
		input.click()
	}

	// Traiter les pièces jointes
	const processEmailAttachments = (html, attachments) => {
		let updatedHtml = html
		const nonInlineAttachments = []

		if (attachments.length) {
			attachments.forEach((attachment) => {
				if (attachment.isInline && attachment.contentId) {
					// Pièces jointes inline
					const base64Image = `data:${attachment.contentType};base64,${attachment.contentBytes}`
					const cidReference = `cid:${attachment.contentId}`

					const imageContainer = `
                            <div style="margin: 10px 0;">
                                <img src="${base64Image}" alt="${
						attachment.name || 'inline image'
					}" 
                                    style="max-width: 100%; max-height: 300px; display: block;" />
                            </div>
                        `

					updatedHtml = updatedHtml.replace(
						new RegExp(`<img[^>]*${cidReference}[^>]*>`, 'g'),
						imageContainer
					)
				} else {
					// Pièces jointes non inline
					nonInlineAttachments.push(attachment)
				}
			})
		}

		return { updatedHtml, nonInlineAttachments }
	}

	const generateFooter = () => {
		const userName = currentUser?.userName || ''
		const userRole = currentUser?.role || ''

		return `
		  <br/><br/>
		  <p style="font-size:12pt;font-family:Aptos,sans-serif;margin:0;">
			<span style="font-family:'Avenir Next LT Pro',sans-serif;">Cordialement,</span>
		  </p>
		  <p style="font-size:12pt;font-family:Aptos,sans-serif;margin:0;">
			<strong><span style="color:#F3901D;">${userName} | ${userRole}</span></strong>
		  </p>
		  <p style="font-size:9pt;font-family:Aptos,sans-serif;margin:0;">
			<i>Tél. : +32(0) 2 537 77 71 | Site Web : <a href="http://www.paycore.be/" target="_blank" style="color:#467886;">www.paycore.be</a></i>
		  </p>
		  <p style="font-size:9pt;font-family:Aptos,sans-serif;margin:0;">
			<i>Courriel : <a href="mailto:contact@paycore.be" style="color:#467886;">contact@paycore.be</a> | Lieu : Avenue Frans Courtens 131 – 1030 Schaerbeek</i>
		  </p>
		  <p style="font-size:7.5pt;color:#92D050;font-family:Aptos,sans-serif;margin:0;">
			<strong>P</strong> Avant de m'imprimer, assurez-vous que c'est tout à fait nécessaire. L'environnement est entre nos mains.
		  </p>
		  <p style="font-size:6pt;color:#CCCCCC;font-family:Aptos,sans-serif;margin:0;">
			Ce message électronique peut contenir des informations confidentielles ou juridiquement privilégiées...
		  </p>
		`
	}

	const handleSendClick = async () => {
		const { updatedEmailBody, inlineAttachments } =
			extractInlineImages(emailBody)
		const sanitizedEmailBody = DOMPurify.sanitize(updatedEmailBody)
		const fullBody = sanitizedEmailBody + generateFooter()

		try {
			// const sanitizedEmailBody = DOMPurify.sanitize(emailBody)
			const response = await axios.post('/api/reply-email', {
				messageId: emails[emails.length - 1].msEmailId,
				subject: `Suivi de votre demande - Ticket #${taskNumber}`,
				sendEmailBody: fullBody,
				taskId,
				attachments: [...attachments, ...inlineAttachments],
				sentBy,
			})
			toast.success('E-mail envoyé avec succès !', {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 5000,
			})
			setEmailBody('')
			setAttachments([])
		} catch (error) {
			console.error('Error sending email:', error)
			toast.error("Échec de l'envoi de l'e-mail.", {
				position: toast.POSITION.BOTTOM_LEFT,
				autoClose: 5000,
			})
		}
	}

	const extractInlineImages = (emailBody) => {
		console.log('emailBody', emailBody)

		const parser = new DOMParser()
		const doc = parser.parseFromString(emailBody, 'text/html')
		console.log("parser.parseFromString(emailBody, 'text/html')", doc)

		const inlineAttachments = []
		const images = doc.querySelectorAll('img[src^="data:"]')

		images.forEach((img) => {
			const src = img.getAttribute('src')
			const mimeType = src.split(';')[0].split(':')[1]
			const base64Data = src.split(',')[1]

			// Generate a unique contentId
			const contentId = uuidv4()

			// Update the email body with the `cid` reference
			img.setAttribute('src', `cid:${contentId}`)

			// Add the inline attachment
			inlineAttachments.push({
				name: `inline-image-${contentId}`,
				contentBytes: base64Data,
				contentType: mimeType,
				contentId, // Unique ID
				isInline: true,
			})
		})

		return {
			updatedEmailBody: doc.body.innerHTML,
			inlineAttachments,
		}
	}

	const handleTemplateChange = (event) => {
		const templateId = event.target.value
		setSelectedTemplate(templateId)

		const selected = templates.find((template) => template.id === templateId)
		if (selected) {
			setEmailBody(selected.body || '')
		}
		setSelectedTemplate('')
	}

	// Handle opening and closing of the Dialog
	const openDialog = () => {
		setPopupBody(emailBody) // Set initial value to current email body
		setIsDialogOpen(true)
	}

	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	const saveDialogText = () => {
		setEmailBody(popupBody) // Save text from popup to emailBody
		closeDialog() // Close the dialog
	}

	const handleRemoveAttachment = (indexToRemove) => {
		setAttachments((prevAttachments) =>
			prevAttachments.filter((_, index) => index !== indexToRemove)
		)
	}

	const formatDate = (timestamp) => {
		return moment(Number(timestamp)).format('LLLL') // e.g., "mercredi 18 décembre 2024 à 10:15"
	}

	// Sort emails by date
	const sortedEmails = [...emails].sort(
		(a, b) => Number(b.createdAt) - Number(a.createdAt)
	)

	if (loading) return <p>Loading templates...</p>
	if (error) return <p>Error loading templates: {error.message}</p>

	return (
		<>
			<ToastContainer />

			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
				PaperProps={{
					style: {
						display: 'flex',
						flexDirection: 'column',
						width: '900px',
						height: '100%',
						padding: '20px',
						overflowY: 'auto',
						paddingTop: '60px',
					},
				}}
			>
				{/* Emails Section */}
				<section style={{ flex: 1, overflowY: 'auto', marginBottom: '20px' }}>
					{sortedEmails.map((email) => {
						const { updatedHtml, nonInlineAttachments } =
							processEmailAttachments(email.email, email.attachments)
						return (
							<Paper
								key={email.id}
								style={{
									margin: '10px 0',
									padding: '15px',
									marginRight: '10px',
									marginLeft: '10px',
								}}
								elevation={3}
							>
								<div
									style={{
										fontWeight: 'bold',
										marginBottom: '5px',
										color: '#555',
									}}
								>
									Expéditeur:{' '}
									<span style={{ fontWeight: 'bold' }}>
										{email?.fromName || 'Nom inconnu'}
									</span>
									<span
										style={{
											fontSize: '0.85rem',
											color: '#999',
											marginLeft: '8px',
										}}
									>
										{`<${email?.fromEmail || 'Email inconnu'}>`}
									</span>
								</div>
								<div
									style={{
										fontSize: '0.85rem',
										marginBottom: '10px',
										color: '#999',
									}}
								>
									{formatDate(email.createdAt)}
									{email.sentBy && (
										<span
											style={{
												fontSize: '0.85rem',
												color: '#555',
												marginLeft: '8px',
											}}
										>
											| envoyé par <strong>{email.sentBy}</strong>
										</span>
									)}
								</div>
								<div
									style={{
										fontSize: '0.95rem',
										fontWeight: 'bold',
										marginBottom: '10px',
										color: '#333',
									}}
								>
									Sujet: {email?.subject || 'Sujet inconnu'}
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(updatedHtml),
									}}
								/>
								<AttachmentList
									attachments={nonInlineAttachments}
									//onRemove={handleRemoveAttachment}
								/>
							</Paper>
						)
					})}
				</section>

				{/* Input Section */}

				<section
					style={{
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'wrap',
						gap: '10px',
						margin: '0 10px',
					}}
				>
					{' '}
					<section
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						{/* Template Selector */}
						<FormControl
							size="small"
							style={{
								width: '300px',
							}}
						>
							<InputLabel>Modèles</InputLabel>
							<Select
								value={selectedTemplate}
								onChange={handleTemplateChange}
								label="Modèles"
							>
								{templates.map((template) => (
									<MenuItem key={template.id} value={template.id}>
										{template.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<section>
							<IconButton
								size="small"
								style={{
									border: '1px solid rgba(0, 0, 0, 0.23)', // Optional: Add an outlined effect
									borderRadius: '4px', // Optional: Match button styling
									padding: '5px',
								}}
								title="Vider le texte"
								onClick={() => setEmailBody('')}
							>
								<ClearIcon />
							</IconButton>
							<IconButton
								size="small"
								style={{
									border: '1px solid rgba(0, 0, 0, 0.23)', // Optional: Add an outlined effect
									borderRadius: '4px', // Optional: Match button styling
									padding: '5px',
									marginLeft: '10px',
								}}
								title="plein ecran"
								onClick={openDialog}
							>
								<FullscreenIcon />
							</IconButton>
						</section>
					</section>
					{/* Email Body Textarea */}
					{/* <TextareaAutosize
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            maxRows={7}
            minRows={3}
            placeholder="Écrivez votre message ici..."
            style={{
              width: "100%",
              padding: "12px",
              fontFamily: "inherit",
              border: "1px solid #ccc",
              borderRadius: "5px",
              resize: "none",
            }}
          /> */}
					<section
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '140px',
							gap: '10px',
						}}
					>
						<ReactQuill
							value={emailBody}
							onChange={setEmailBody}
							modules={{
								toolbar: [
									[{ header: [1, 2, false] }],
									['bold', 'italic', 'underline'],
									[{ color: [] }, { background: [] }],
									[{ align: [] }],
									[{ list: 'ordered' }, { list: 'bullet' }],
									['link', 'image'],
									['clean'],
								],
							}}
							style={{ height: '100px' }}
						/>
					</section>
					<section
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '20px',
						}}
					>
						{/* Left: Attachment Button */}
						<Button
							variant="outlined"
							startIcon={<AttachmentIcon />}
							onClick={handleAttachmentClick}
							style={{ marginRight: '10px' }}
						>
							Joindre
						</Button>

						{/* Center: Attachments */}
						<div
							style={{
								flex: 1,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div>
								{console.log('attachments', attachments)}
								{attachments.length > 0 && (
									<AttachmentList
										attachments={attachments}
										onRemove={handleRemoveAttachment}
										horizontal
									/>
								)}
							</div>
						</div>

						{/* Right: Send Button */}
						<Button
							variant="contained"
							endIcon={<SendIcon />}
							onClick={handleSendClick}
						>
							Envoyer
						</Button>
					</section>
				</section>
			</Drawer>
			{/* Dialog for Fullscreen Text Editor */}
			<Dialog open={isDialogOpen} onClose={closeDialog} fullWidth>
				<DialogTitle>Éditeur plein écran</DialogTitle>
				<DialogContent style={{ height: '250px' }}>
					{/* <TextareaAutosize
            value={popupBody}
            onChange={(e) => setPopupBody(e.target.value)}
            minRows={10}
            style={{
              width: "100%",
              padding: "10px",
              fontFamily: "inherit",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          /> */}
					<ReactQuill
						value={popupBody}
						onChange={setPopupBody}
						modules={{
							toolbar: [
								[{ header: [1, 2, false] }],
								['bold', 'italic', 'underline'],
								[{ color: [] }, { background: [] }],
								[{ align: [] }],
								[{ list: 'ordered' }, { list: 'bullet' }],
								['link', 'image'],
								['clean'],
							],
						}}
						style={{ height: '200px' }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog} color="secondary">
						Annuler
					</Button>
					<Button onClick={saveDialogText} color="primary">
						Enregistrer
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default EmailDrawer
